import { createAsyncThunk } from '@reduxjs/toolkit';
import ordersApi from '../../../services/Customer/ordersApi';

export const fetchUserOrders = createAsyncThunk(
  'user/fetchUserOrders',
  async () => await ordersApi.getTests(),
);

export const fetchTestParts = createAsyncThunk(
  'user/fetchTestParts',
  async () => await ordersApi.getTestParts(),
);

export default {
  fetchUserOrders,
  fetchTestParts,
};
