import userAuthorizedAxios from './userAuthorizedAxios';
import {
  ActivateBloodResultDTO,
  ActivateMbd,
  ActivateTestRequest,
  PurchaseHistoryResponse, TestParts,
  TestsResponse,
  TransferTestRequest,
} from '../../interfaces/Orders/Tests';
import { AddressPost } from '../../interfaces/Profile/Account/postBody';
import customerAuthorizedFileDownloader from "./customerAuthorizedFileDownloader";

export default {
  getTests: (): Promise<TestsResponse> => userAuthorizedAxios.get<TestsResponse>('/api/customer/v1/tests')
    .then((res) => res.data),

  getTestParts: (): Promise<TestParts[]> => userAuthorizedAxios.get<TestParts[]>('/api/customer/v1/test-parts')
    .then((res) => res.data),

  combineTest: (testIds: number[]): Promise<number> => userAuthorizedAxios.post('/api/customer/v1/test-parts/combine', {
    testPartsIds: testIds,
  })
    .then((res) => res.data.testId),

  decombineTest: (testId: number): Promise<null> => userAuthorizedAxios.delete(`/api/customer/v1/blood/results/${testId}/decombine`),

  getPurchaseHistory: (): Promise<PurchaseHistoryResponse[]> => userAuthorizedAxios.get<PurchaseHistoryResponse[]>('/api/pages/purchaseHistory/orders')
    .then((res) => res.data),

  activateTest: (id: number): Promise<null> => userAuthorizedAxios.post('/api/pages/orders/activateTest', {
    testId: id,
  } as ActivateTestRequest)
    .then((res) => res.data),

  activateMbd: (data: ActivateMbd): Promise<null> => userAuthorizedAxios.post('/api/pages/orders/activateMbd', data)
    .then((res) => res.data),

  activateBloodResult: (id: number, data: ActivateBloodResultDTO): Promise<null> => userAuthorizedAxios.post(`/api/customer/blood/results/${id}/activate`, data).then((res) => res.data),
  validatePhone: (phone: string): Promise<null> => userAuthorizedAxios.post('/api/pages/validate/phone', {
    phone,
  })
    .then((res) => res.data),
  validateAddress: (address: AddressPost): Promise<null> => userAuthorizedAxios.post('/api/pages/validate/address', address)
    .then((res) => res.data),
  transferTest: (data: TransferTestRequest) => userAuthorizedAxios.post('/api/customer/v1/order/transfer', data),

  downloadReceiptPdf: (storeOrderId: number) => customerAuthorizedFileDownloader(
    `/api/customer/v1/order/receipt/${storeOrderId}`,
    null,
    'receipt.pdf',
    'GET',
  ),
};
