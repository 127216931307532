/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersState } from './types';
import { Test } from '../../../interfaces/Orders/Tests';
import { fetchTestParts, fetchUserOrders } from './actions';
import ActivationStep from '../../../Constants/Customer/Orders/ActivationFlowSteps';

export const initialOrdersState: OrdersState = {
  availableTests: null,
  previousTests: null,
  activatedTest: null,
  activationFlow: {
    currentStep: null,
    nextStep: null,
    testFromActivating: null,
    testForActivating: null,
    notifiedAboutPregnancy: false,
    mbdSuggested: false,
  },
  diyOrderInfo: null,
  transferredTests: null,
  testParts: null,
};

const switchStep = (state: OrdersState, activationStep: ActivationStep) => {
  console.log(`Activation move on ${activationStep}`);
  if (state.activationFlow.currentStep === ActivationStep.PREGNANCY_STEP) {
    state.activationFlow.notifiedAboutPregnancy = true;
  }

  if (state.activationFlow.currentStep === ActivationStep.SHOW_QUEST_DRAW_POPUP) {
    state.activationFlow.mbdSuggested = true;
  }

  state.activationFlow = {
    ...state.activationFlow,
    currentStep: activationStep,
    nextStep: activationStep,
  };

  const testForActivation = state.activationFlow.testForActivating;
  if (!testForActivation) {
    return;
  }

  if (testForActivation.notifyAboutPregnancy && !state.activationFlow.notifiedAboutPregnancy) {
    state.activationFlow.currentStep = ActivationStep.PREGNANCY_STEP;
    state.activationFlow.nextStep = activationStep;
    return;
  }

  // MBD upgrade suggestion for combined test
  if (!state.activationFlow.mbdSuggested && testForActivation.packageId === 59 && !testForActivation.hasMbd) {
    state.activationFlow.currentStep = ActivationStep.SHOW_QUEST_DRAW_POPUP;
    state.activationFlow.nextStep = activationStep;
  }
};

const customerOrdersSlice = createSlice({
  name: 'userOrders',
  initialState: initialOrdersState,
  reducers: {
    resetActivation: (state) => {
      console.log('Activation flow cancelled');
      state.activationFlow = {
        testFromActivating: null,
        testForActivating: null,
        currentStep: null,
        nextStep: null,
        notifiedAboutPregnancy: false,
        mbdSuggested: false,
      };
    },

    moveOnActivation: (state, action: PayloadAction<ActivationStep>) => {
      const activationStep = action.payload;
      switchStep(state, activationStep);
    },

    startActivation: (state, action: PayloadAction<Test>) => {
      console.log('Activation flow started');

      const { activatedTest } = state;
      const testForActivation = action.payload;

      const toStep = (step: ActivationStep) => {
        console.log(`Activation flow to ${step} step`);
        state.activationFlow = {
          testFromActivating: activatedTest,
          testForActivating: testForActivation,
          currentStep: step,
          nextStep: step,
          notifiedAboutPregnancy: false,
          mbdSuggested: false,
        };

        switchStep(state, step);
      };

      if (activatedTest && activatedTest.hasMbd && !activatedTest.isRescheduleAvailable) {
        toStep(ActivationStep.EXPIRE_STEP);
        return;
      }

      if (activatedTest) {
        toStep(ActivationStep.CONFIRM_ACTIVATING_STEP);
        return;
      }

      if (!testForActivation.hasHomeKit && !testForActivation.hasMbd && testForActivation.shouldPreventActivationWithoutMbd) {
        toStep(ActivationStep.CONFIRM_LAB_TEST);
        return;
      }

      toStep(ActivationStep.CONFIRM_ACTIVATING_STEP);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserOrders.fulfilled, ((state, action) => {
      const {
        availableTests, previousTests, transferredTests, diyOrderInfo,
      } = action.payload;
      state.availableTests = availableTests;
      state.previousTests = previousTests;
      state.transferredTests = transferredTests;
      state.diyOrderInfo = diyOrderInfo;

      state.activatedTest = availableTests.find((test: Test) => test.isActivated);
    }));
    builder.addCase(fetchTestParts.fulfilled, ((state, action) => {
      state.testParts = action.payload;
    }));
  },
});

export const {
  resetActivation,
  startActivation,
  moveOnActivation,
} = customerOrdersSlice.actions;

export default customerOrdersSlice.reducer;
