enum ActivationStep {
  EXPIRE_STEP = 'EXPIRE',
  PREGNANCY_STEP = 'PREGNANCY',
  MBD_REQUIRED_STEP = 'MBD_REQUIRED',
  CONFIRM_ACTIVATING_STEP = 'CONFIRM_ACTIVATING',
  TEST_ACTIVATED_STEP = 'TEST_ACTIVATED',
  SCHEDULE_MBD_STEP = 'SCHEDULE_MBD',
  CONFIRM_LAB_TEST = 'CONFIRM_LAB_TEST',
  SHOW_QUEST_DRAW_POPUP = 'SHOW_QUEST_DRAW_POPUP',
}

export default ActivationStep;
